import React, { FC, useContext } from 'react';
import { AlertMessage } from 'components/AlertMessage';
import { Box, Text } from '@endpoint/blockparty';
import { CompanyContext, useAppConfigsContext } from 'utils/context';

import { formatPhone } from '../../../../../../utils/formatPhone';

const PinnedItemCustomMessage: FC = () => {
  const { companyName } = useContext(CompanyContext);
  const { COMPANY_PHONE_NUMBER } = useAppConfigsContext();

  return (
    <Box data-test-id="pinned-item-error-text">
      <Text>Please try to refresh the page. If the problem persists, please call {companyName} for assistance at</Text>{' '}
      <Text as="a" href={`tel:+1${COMPANY_PHONE_NUMBER?.technicalSupport}`}>
        {formatPhone(COMPANY_PHONE_NUMBER?.technicalSupport ?? '')}
      </Text>{' '}
      <Text>or use the chat icon to message us directly.</Text>
    </Box>
  );
};

export const PinnedItemErrorMessage: FC = () => (
  <Box m="space60">
    <AlertMessage description=" " title="Oops! Something went wrong">
      <PinnedItemCustomMessage />
    </AlertMessage>
  </Box>
);
