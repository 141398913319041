import React, { FC } from 'react';
import { Box, Heading, Text, Flex, Button } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';
import { RouteOptions } from 'Auth/Utilities/Constant';
import { useAppConfigsContext } from 'utils/context';
import { formatPhone } from 'utils/formatPhone';

const UnverifiedUser: FC = () => {
  const navigate = useNavigate();
  const { COMPANY_EMAIL, COMPANY_PHONE_NUMBER } = useAppConfigsContext();

  return (
    <Box textAlign="center">
      <Heading as="h1" mb="space50" size="fontSize60">
        Verification needed
      </Heading>
      <Text as="p">
        It looks as though you have not finished creating your account. To ensure your account remains secure,{' '}
        <b>please contact us</b> at{' '}
        <Text as="a" color="blue500" href={`mailto:${COMPANY_EMAIL}`}>
          {COMPANY_EMAIL}
        </Text>{' '}
        or{' '}
        <Text as="a" color="blue500" href={`tel:+1${COMPANY_PHONE_NUMBER?.generalInquiries}`} textDecoration="none">
          {formatPhone(COMPANY_PHONE_NUMBER?.generalInquiries ?? '')}
        </Text>{' '}
        and we’ll help get you logged in.
      </Text>
      <Flex flex={1} justifyContent="center" mb="space70" mt="space80" width="100%">
        <Button onClick={() => navigate(RouteOptions.SIGN_IN)}>Back to Sign In</Button>
      </Flex>
    </Box>
  );
};

export default UnverifiedUser;
