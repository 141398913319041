import React from 'react';
import { Box, Heading, Text, Flex, Button } from '@endpoint/blockparty';
import { useNavigate } from 'react-router';

import { RouteOptions } from '../../Utilities/Constant';
import { useAppConfigsContext } from '../../../utils/context';
import { formatPhone } from '../../../utils/formatPhone';

function ForgotEmail() {
  const navigate = useNavigate();
  const { COMPANY_EMAIL, COMPANY_PHONE_NUMBER } = useAppConfigsContext();

  return (
    <>
      <Box mb="space80" textAlign="center">
        <Heading as="h2" mb="space40" size="fontSize60">
          Forgot email
        </Heading>

        <Text>
          We’ve got you covered. Please contact us at{' '}
          <Text as="a" color="blue500" href={`mailto:${COMPANY_EMAIL}`}>
            {COMPANY_EMAIL}
          </Text>{' '}
          or{' '}
          <Text as="a" color="blue500" href={`tel:+1${COMPANY_PHONE_NUMBER?.generalInquiries}`}>
            {formatPhone(COMPANY_PHONE_NUMBER?.generalInquiries ?? '')}
          </Text>{' '}
          and we’ll help get you logged in.
        </Text>
      </Box>
      <Flex flex={1} justifyContent="center" width="100%">
        <Button mb="space70" onClick={() => navigate(RouteOptions.SIGN_IN)}>
          Back to Sign In
        </Button>
      </Flex>
    </>
  );
}

export default ForgotEmail;
