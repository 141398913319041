import React, { FC } from 'react';
import { Box, Flex, Text } from '@endpoint/blockparty';

import { useAppConfigsContext } from '../../utils/context';
import { formatPhone } from '../../utils/formatPhone';
import { Svg } from '../Svg';

type ErrorPageProps = {
  heading?: string;
  body?: string;
  styles?: object;
  testId?: string;
};

export const ErrorPage: FC<ErrorPageProps> = ({ heading, body, styles, testId }) => {
  const { COMPANY_PHONE_NUMBER } = useAppConfigsContext();

  return (
    <div data-test-id={testId || 'error-page'} style={styles}>
      <Flex alignItems="center" height="100%" justifyContent="center">
        <Box margin="0 auto" p={{ base: 'space50', md: 'space60' }} style={{ width: '524px' }} textAlign="center">
          <Svg aria-label="500Dogs" dataTestId="500Dogs" mb="space80" name="Dogs" viewBox="0 0 476 288" />
          <Text as="h1" color="carbon900" mb="space60" size="fontSize60">
            {heading || 'Oops! Something went wrong.'}
          </Text>
          <Text as="p" size="fontSize20" textAlign="center">
            {body ||
              `Try to refresh the page. If the problem persists, please feel free to contact us at ${formatPhone(
                COMPANY_PHONE_NUMBER?.generalInquiries ?? '',
              )}.`}
          </Text>
        </Box>
      </Flex>
    </div>
  );
};
